import dayjs from 'dayjs';

export default function useDateFromIsoString() {
  const { t } = useI18n();

  const formatRelativeTime = (isoDate) => {
    if (!isoDate) return '';

    const now = new Date();
    const timestamp = new Date(isoDate);

    const diff = now.getTime() - timestamp.getTime();
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (minutes < 60) {
      return `${minutes} ${t('time.one_letter.minute')}`;
    } else if (hours < 24) {
      return `${hours} ${t('time.one_letter.hour')}`;
    } else if (days < 7) {
      return `${days} ${t('time.one_letter.day')}`;
    } else {
      return dayjs(timestamp).format('DD.MM');
    }
  };

  return {
    formatRelativeTime,
  };
}
